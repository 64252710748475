#events_module.cms_list {
	.events-filters {
		background-color: #dddddd;
		border-radius: 5px;
		padding: 10px;
		margin-bottom: 10px;
		overflow: hidden;

		label {
			font-weight: bold;
			color: #333333;
		}

		.ui-form-label {
			padding-bottom: 3px;
		}

		.filter {
			float: left;
			padding-right: 15px;

			input.datepicker {
				&:not(.hasDatepicker) {
					width: 150px;
					height: 27px;
				}
			}

			input.datepicker.hasDatepicker {
				height: 27px;
				width: 110px;
			}

			input[name="Keywords"] {
				width: 100%;
				height: 27px;
				margin-top: -1px;
				max-width: 340px;
			}

			img {
				cursor: pointer;
				vertical-align: text-top;
				margin-left: 3px;
			}
		}

		button {
			float: left;
			padding: 3px 5px;
			position: relative;
			bottom: -20px;
		}
	}

	.filter-by-category-wcag {
		position: absolute;
		left: -10000px;
		top: auto;
		width: 1px;
		height: 1px;
		overflow: hidden;
	}

	.event-current-day {
		padding-top: 10px;

		.cms_title {
			float: left;
			display: inline;

			h2 {
				margin: 0px;
			}
		}

		#event-calendar-link {
			float: right;
			display: inline;
		}
	}

	.events-category-name {
		font-weight: bold;
	}

	.events-under-category {
		padding-left: 10px;
	}

	.legend {
		padding-top: 15px;

		/* Registration styles */
		.calendar_registration_open {
			background-image: url("/core/website/modules/events/assets/images/icons/registration_open_small.svg") !important;
			background-position: 0 0 !important;
			background-repeat: no-repeat !important;
			background-size: 15px 15px !important;
			padding-left: 18px !important;
			padding-bottom: 3px;
		}

		.calendar_registration_closed {
			background-image: url("/core/website/modules/events/assets/images/icons/registration_open_small.svg") !important;
			background-position: 0 0 !important;
			background-repeat: no-repeat !important;
			background-size: 15px 15px !important;
			padding-left: 18px !important;
			padding-bottom: 3px;
			background-image: url("/core/website/modules/events/assets/images/icons/registration_closed_small.svg") !important;
		}
	}

	.list-view-bottom-padding {
		padding-bottom: 10px;
	}

	.events-rss-link {
		display: block;
		float: left;
		text-decoration: none;

		img {
			display: block;
			padding-left: 4px;
		}
	}

	.events-calendar-link {
		display: block;
		float: left;
		text-decoration: none;

		img {
			display: block;
			padding-left: 4px;
		}
	}

	#agenda.increase-line-height-link {
		.js-view-all {
			float: left;
			line-height: 24px;
		}
	}

	#event-calendar-link.increase-line-height-link {
		.js-view-calendar {
			float: left;
			line-height: 24px;
		}
	}

	.registration_open {
		background-image: url("/core/website/modules/events/assets/images/icons/registration_open_small.svg");
		background-repeat: no-repeat;
		background-position: 0 50%;
		background-size: 15px 15px;
		padding-left: 19px;
	}

	.registration_closed {
		background-image: url("/core/website/modules/events/assets/images/icons/registration_closed_small.svg");
		background-repeat: no-repeat;
		background-position: 0 50%;
		background-size: 15px 15px;
		padding-left: 19px;
	}

	.cms_title {
		font-size: 0.75rem;
		padding-top: 0px;
	}

	.cms_dsp_td {
		padding-right: 6px;
	}

	.cms_button {
		margin-top: -3px;
		background-color: #f6f6f6;
		border: 1px solid #cccccc;
		padding: 2px 5px 2px 5px;

		&:hover {
			background-color: #dddddd;
			box-shadow: 0 0px 2px #333;
		}
	}

	.cms_divider {
		border-top: 1px solid #e1e3e0;
		height: 0px;
		margin-bottom: 10px;
		margin-top: 10px;
	}

	.cms_date {
		font-size: 0.75rem;
		font-weight: bold;
		margin-bottom: 10px;
		clear: left;
	}

	.cms_date.cms_date_with_time_comments {
		margin-bottom: 3px;
	}

	.cms_oddRow {
		background: #ffffff;
	}

	.cms_emptyCell {
		border-width: 0px;
		padding: 3px;
		text-align: center;
		width: 14%;
	}

	.cms_filledCell {
		border-width: 0px;
		height: 70px;
		padding: 3px;
		text-align: center;
		vertical-align: top;
		width: 14%;

		.cms_evt_td {
			border-width: 0px;

			ul {
				margin: 0px 0px 0px 16px;
				padding: 0px 0px 0px 0px;
				text-align: left;
			}

			li {
				padding-bottom: 3px;
			}
		}
	}

	.cms_counter {
		border-width: 0px;
		font-weight: bold;
	}

	.cms_list_item {

		.cms_title {
			margin-bottom: 0px;
			padding-bottom: 0px;
			float: left;
			width: 95%;
		}

		.cms_date {
			margin-top: 0px;
			padding-top: 0px;
		}

		.cms_description {
			margin: 0px;
			padding: 0px;
		}

		.cms_cat_ckb_td {
			padding: 2px;
		}

		.cms_cat_lst_td {
			padding: 7px 7px 7px 0px;
		}
	}

	div.events_module_calendar {
		margin: 0px auto;
		position: relative;
		width: 100%;

		.fc-view {
			.fc-scrollgrid-section-header {
				table {
					tr {
						th.fc-col-header-cell {
							background: #666666;
							color: #ffffff;

							a {
								color: #ffffff;
								text-decoration: none;
								border-bottom: 0 solid;
								cursor: default;
								background-color: #666666;
							}
						}
					}
				}
			}
		}

		.fc-view.fc-list {
			.fc-list-day-text,
			.fc-list-day-side-text {
				text-decoration: none;
				border-bottom: 0px solid;
				color: inherit;
				cursor: default;

				&:hover {
					text-decoration: none;
					border-bottom: 0px solid;
					color: inherit;
				}
			}
		}

		.fc-view-container {
			.fc-event {
				background-color: #757575;
				border-color: #bbbbbb;
				color: #000000;
				font-size: 0.75rem;
				margin-top: 2px;

				span {
					background: none;
					border: none;
					white-space: normal;
				}
			}
		}
	}

	/* Registration styles */
	.events_module_calendar {
		.calendar_registration_open {
			background-image: url("/core/website/modules/events/assets/images/icons/registration_open_small.svg") !important;
			background-position: 0 0 !important;
			background-repeat: no-repeat !important;
			background-size: 15px 15px !important;
			padding-left: 18px !important;
			padding-bottom: 3px;
		}

		.calendar_registration_closed {
			background-image: url("/core/website/modules/events/assets/images/icons/registration_open_small.svg") !important;
			background-position: 0 0 !important;
			background-repeat: no-repeat !important;
			background-size: 15px 15px !important;
			padding-left: 18px !important;
			padding-bottom: 3px;
			background-image: url("/core/website/modules/events/assets/images/icons/registration_closed_small.svg") !important;
		}

		.fc-list {
			.calendar_registration_open {
				background-image: none !important;
				background-position: 5px 50% !important;
				background-size: 14px 14px !important;
				margin-left: 0 !important;
				padding-left: 0 !important;

				a {
					background-image: url("/core/website/modules/events/assets/images/icons/registration_closed_small.svg") !important;
					background-position: 5px 50% !important;
					background-size: 14px 14px !important;
					margin-left: 0 !important;
					padding-left: 25px !important;
					background-repeat: no-repeat !important;
					background-image: url("/core/website/modules/events/assets/images/icons/registration_open_small.svg") !important;
				}
			}

			.calendar_registration_closed {
				background-image: none !important;
				background-position: 5px 50% !important;
				background-size: 14px 14px !important;
				margin-left: 0 !important;
				padding-left: 0 !important;

				a {
					background-image: url("/core/website/modules/events/assets/images/icons/registration_closed_small.svg") !important;
					background-position: 5px 50% !important;
					background-size: 14px 14px !important;
					margin-left: 0 !important;
					padding-left: 25px !important;
					background-repeat: no-repeat !important;
				}
			}

			.fc-list-event-title {
				&:not(.calendar_registration_open) {
					&:not(.calendar_registration_closed) {
						padding-left: 6px;
					}
				}
			}
		}
	}

	/* Month view */
	.fc-axis.fc-widget-content {
		background: #666666;
		color: #ffffff;
	}

	.fc-daygrid {

		.fc-daygrid-day-number {
			text-decoration: none;
			border: 0 solid;
			cursor: default;
		}

		.fc-event {
			overflow: hidden;

			.fc-event-content {
				display: flex;
				flex-direction: column;
				row-gap: 0.2rem;
				padding: 0.2rem;

				.fc-time {
					line-height: calc(var(--fc-small-font-size) + 0.1rem);
					display: block;
					text-overflow: ellipsis;
					white-space: nowrap;

					strong {
						font-weight: bold;
					}
				}

				.fc-title {
					line-height: calc(var(--fc-small-font-size) + 0.1rem);
					display: block;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
		}

		.fc-day.fc-day-sat {
			background: #efefef;
			color: #636363;
			background-clip: padding-box;
		}

		.fc-day.fc-day-sun {
			background: #efefef;
			color: #636363;
			background-clip: padding-box;
		}

		.fc-day.fc-day-other {
			background: #dfdfdf;
			color: #616163;
			border: 1px solid #cccccc;
			opacity: 1;
			background-clip: padding-box;
		}
	}

	.fc-timegrid {
		.fc-event {
			overflow: hidden;

			.fc-event-content {
				display: flex;
				flex-direction: column;
				row-gap: 0.2rem;
				padding: 0.2rem;

				.fc-time {
					line-height: calc(var(--fc-small-font-size) + 0.1rem);
					display: block;

					strong {
						font-weight: bold;
					}
				}

				.fc-title {
					line-height: calc(var(--fc-small-font-size) + 0.1rem);
					display: block;
				}
			}
		}

		.fc-day.fc-day-sat {
			background: #efefef;
			color: #636363;
			background-clip: padding-box;
		}

		.fc-day.fc-day-sun {
			background: #efefef;
			color: #636363;
			background-clip: padding-box;
		}

		.fc-day.fc-day-other {
			background: #dfdfdf;
			color: #616163;
			border: 1px solid #cccccc;
			opacity: 1;
			background-clip: padding-box;
		}
	}

	/* List Styles */
	.fc-list-event {
		.fc-list-event-dot {
			border-radius: 0;
		}

		&:hover {
			td {
				.fc-list-event-dot[style="background-color:#ffffff"] {
					background-color: #f5f5f5 !important;
					border-radius: 0;
				}
			}
		}
	}

	.fc-list-item {
		td {
			a {
				&:hover {
					border: 0 none;
					box-shadow: none;
					text-decoration: none;
					color: inherit;
				}
			}
		}
	}

	.found-in-category-help-text-wcag {
		position: absolute;
		left: -10000px;
		top: auto;
		width: 1px;
		height: 1px;
		overflow: hidden;
	}
}

/* Categories */
#events_module {
	.category-wrapper {
		display: inline-block;
		margin-right: 10px;
	}

	.calendar.month {
		border-collapse: collapse;
	}

	.calendar.week {
		border-collapse: collapse;
	}

	.calendar {
		.day {
			border: 1px solid;
			height: 7em;
			vertical-align: top;
		}
	}

	#fullcalendar-controls {
		clear: both;
		overflow: hidden;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-end;

		#categories {
			float: left;

			label {
				display: inline;
			}
		}

		#agenda {
			float: right;
		}

		#timeZone {
			float: right;
			padding-right: 20px;
		}

		.category-accordion {
			display: none;
		}

		.category-accordion-panel {
			display: block;
			max-height: none;
		}
	}

	.category {
		position: relative;
		top: 2px;
	}

	.category-swatch {
		display: inline-block;
		height: 10px;
		width: 10px;
	}

	.category-swatch.uncategorized {
		border: 1px solid #000000;
		background-color: #ffffff;
	}

	.list-view-link-wcag {
		position: absolute;
		left: -10000px;
		top: auto;
		width: 1px;
		height: 1px;
		overflow: hidden;
	}

	#event-calendar-link {
		display: none;
		float: right;
	}

	.event-category-label {
		font-size: 0.813rem;
		margin-bottom: 4px;
		clear: left;
	}
}

#events_module.homepage_contentbox {
	.registration_open {
		background-image: url("/core/website/modules/events/assets/images/icons/registration_open_small.svg");
		background-repeat: no-repeat;
		background-position: 0 50%;
		background-size: 15px 15px;
		padding-left: 19px;
	}

	.registration_closed {
		background-image: url("/core/website/modules/events/assets/images/icons/registration_closed_small.svg");
		background-repeat: no-repeat;
		background-position: 0 50%;
		background-size: 15px 15px;
		padding-left: 19px;
	}

	.event_list {
		table {
			tr {
				td.date {
					padding: 0px 5px 0px 0px;
				}
			}
		}
	}

	.registration-icon {
		background-image: url("/core/website/modules/events/assets/images/icons/registration_open_small.svg");
		background-repeat: no-repeat;
		background-position: 0 50%;
		display: inline-block;
		background-size: 15px 15px;
		margin-right: 2px;
		width: 15px;
		height: 15px;
	}

	.registration-icon.closed-registration {
		background-image: url("/core/website/modules/events/assets/images/icons/registration_closed_small.svg");
	}
}

/*Event Details view */
#events_module.cms_entity {
	.cms_date {
		font-weight: bold;
	}

	.cms_registration_closed {
		color: #ff0000;
		padding-bottom: 5px;
	}

	.cms_description {
		overflow: hidden;
	}

	.cms_divider {
		border-top: 1px solid #e1e3e0;
		height: 0px;
		margin-top: 27px;
		margin-bottom: 27px;
	}

	.cms_time_comments {
		line-height: 1.5em;
		margin-top: 0.5em;
	}

	.events-detail-date {
		font-size: 1.25em;
		line-height: 1.25em;
		margin-bottom: 0.25em;
		margin-top: 1em;
	}

	.registration-icon {
		background-image: url("/core/website/modules/events/assets/images/icons/registration_open_small.svg");
		background-repeat: no-repeat;
		background-position: 0 50%;
		display: inline-block;
		background-size: 30px 30px;
		margin-right: 7px;
		width: 30px;
		height: 30px;
		vertical-align: middle;
	}

	.registration-icon.closed-registration {
		background-image: url("/core/website/modules/events/assets/images/icons/registration_closed_small.svg");
	}

	.events-detail-item.registration-status {
		display: inline;
	}

	.event-full-details {
		>div {
			&:last-of-type {
				>div {
					margin-bottom: 0;
				}
			}
		}
	}

	.cms_contact_name {
		overflow: hidden;
	}

	.cms_contact_phone {
		overflow: hidden;
	}

	.cms_location {
		overflow: hidden;
	}

	.cms_contact_name_label {
		margin-bottom: 0.753em;
		float: left;
		width: 15%;
		font-weight: 700;
	}

	.cms_contact_phone_label {
		margin-bottom: 0.753em;
		float: left;
		width: 15%;
		font-weight: 700;
	}

	.cms_location_label {
		margin-bottom: 0.753em;
		float: left;
		width: 15%;
		font-weight: 700;
	}

	.cms_contact_name_value {
		float: left;
		width: 75%;
	}

	.cms_contact_phone_value {
		float: left;
		width: 75%;
	}

	.cms_location_value {
		float: left;
		width: 75%;
	}

	.event-categories-wrapper {
		margin-bottom: 27px;
	}

	.registration-wrapper {
		margin-top: 27px;

		.event-registration-button-description {
			font-size: small;
		}
	}

	.registration-info-wrapper {
		margin-bottom: 14px;
	}

	.event-categories-label {
		display: inline-block;
		color: #555;
		margin-right: 1em;
		overflow: hidden;
	}

	.events-detail-item.category-label {
		overflow: hidden;
		border-bottom: 0 none;
		display: inline-block;
		font-size: 0.875em;
		line-height: 1.25em;
		margin-right: 1.5em;

		.category-border-left {
			padding-right: 0.375em;
			border-left: 18px solid #000;
			float: left;
		}

		.category-link-text {
			float: left;
		}
	}
}

/* Event Form */
#events_module.cms_form {
	.cms_info_req {
		float: right;
	}

	.cms_divider {
		border-top: 1px solid #e1e3e0;
		height: 0px;
		margin-top: 5px;
		margin-bottom: 5px;
	}

	.cms_label {
		background-color: #f6f6f6;
		padding: 3px 5px 3px 0px;
		text-align: right;
		width: 32%;
	}

	.cms_error {
		color: #cc0000;
		font-weight: bold;
	}

	.cms_required {
		background-color: #f6f6f6;
		background-image: url("/core/public/shared/assets/images/required.gif");
		background-repeat: no-repeat;
		background-position: center;
		text-align: center;
		width: 5%;
	}

	.cms_non_required {
		background-color: #f6f6f6;
		text-align: center;
		width: 5%;
	}

	.cms_field {
		background-color: #f6f6f6;
		padding: 3px 0px 3px 3px;
		width: 63%;
	}

	.cms_additional_info {
		margin-bottom: 0px;
	}

	.cms_td_answers {
		padding: 2px;
	}

	.cms_button {
		background-color: #f6f6f6;
		border: 1px solid #cccccc;
		padding: 2px 5px 2px 5px;

		&:hover {
			background-color: #dddddd;
			box-shadow: 0 0px 1px #ccc;
		}
	}
}

/* --- calendar --- */
&:root {
	--fc-small-font-size: 0.75rem;
	--fc-button-bg-color: #efefef;
	--fc-button-border-color: #efefef;
	--fc-button-hover-bg-color: #ccc;
	--fc-button-hover-border-color: #ccc;
	--fc-button-active-bg-color: #dddddd;
	--fc-button-active-border-color: #dddddd;
	--fc-button-text-color: #000;
	--fc-event-border-color: #ffffff;
}

.tippy-event-time {
	font-weight: bold;
}

.tippy-event-description {
	margin-top: 6px;
}

.tippy-event-registration {
	margin-top: 6px;
}

.icaldownload {
	float: right;
	width: 5%;
	text-align: right;
}

.icaldownload-text {
	display: none;
}

.allCallendarsLabel {
	display: inline;
}

.allCalendarToggleDivider {
	padding-top: 10px;
}

.icaldownload-confirmation {
	display: inline-flex;
	gap: 0.5rem;
	align-items: flex-start;
}

/*conference layout content box*/
#events_module.homepage_contentbox.conference-tabs {
	position: relative;

	.tabs-menu {
		position: relative;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
	}

	.conference-heading.tabbed {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		padding: 0.5rem 0;
		border-left: 2px solid #cccccc;
		background-color: #000000;
		color: #ffffff;
		position: relative;
		vertical-align: top;
		text-decoration: none;
		text-align: left;
		cursor: pointer;
		max-width: 100%;
	}

	.conference-heading.tabbed.current {
		background-color: #ffffff;
		color: #000000;
		font-weight: bold;
	}

	.tab-heading-text {
		color: inherit;
		font-size: inherit;
	}

	.tab-content {
		position: relative;
		display: block;
		overflow: hidden;
	}

	.tab-pane {
		position: relative;
		display: none;
	}

	.tab-active {
		display: block;
	}

	.schedule-wrapper {
		background: #ffffff;
	}

	.individual-event-time-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		border-bottom: 1px solid #cccccc;
	}

	.event-time {
		width: 20%;
		padding-top: 0.35rem;
		padding-bottom: 0.35rem;
		padding-left: 1rem;

		span {
			text-transform: lowercase;
		}
	}

	.time-description {
		width: 80%;
		padding-top: 0.35rem;
		padding-right: 1rem;
		padding-left: 1rem;
		padding-bottom: 0.35rem;
	}

	.event-date {
		display: none;
	}
}

@media (max-width: 767px) {
	#events_module.homepage_contentbox.conference-tabs {
		.tabs-menu {
			display: none !important;
		}

		.tab-pane {
			display: block;
		}

		.event-date {
			display: inline;
			padding-top: 0.35rem;
			padding-bottom: 0.35rem;
			padding-left: 1rem;
		}
	}
}

/* Responsive FullCalendar */
@media (max-width: 767px) {
	#events_module {
		#fullcalendar-controls {
			justify-content: flex-start;
			flex-direction: column;
			align-items: flex-start;

			#agenda {
				float: right;
				width: 100%;
				margin: 10px 0px 0px 0px;
				display: block;
			}

			#timeZone {
				margin: 2rem 0;
				width: 100%;
				border-bottom: 1px solid #c9c6c4;
				padding: 0rem 0 2rem;
			}

			.category-accordion {
				display: block;
				background-color: #eee;
				color: #444;
				cursor: pointer;
				padding: 18px;
				width: 100%;
				border: none;
				text-align: left;
				outline: none;
				font-size: 17px;
				transition: 0.4s;
			}

			.category-accordion:after {
				content: '\002B';
				color: #777;
				font-weight: bold;
				float: right;
				margin-left: 5px;
			}

			.category-accordion.active:after {
				content: "\2212";
			}

			.category-accordion-panel {
				display: none;
				max-height: 0;
				padding: 14px;
				transition: max-height 0.2s ease-out;
				border-bottom: 1px solid #c9c6c4;
				width: 100%;
				padding-bottom: 2rem;
			}
		}
	}
}

@media (max-width: 991px) {
	.fc-toolbar {
		flex-direction: column;
		row-gap: 1rem;
	}
}
